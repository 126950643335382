$(document).ready(function () {
    var btnLogin = $('.js-login, .btn-selection'),
        modalLogin = $('.modal-login'),
        form = $('.modal-login form');

    btnLogin.on('click', function (e) {
        modalLogin.modal('show');
        return false;
    });

    $('.js-show-inscription, .js-show-login').on('click', function (e) {
        $(this).closest('.card').toggleClass('flipped');
        return false;
    });


    form.find('input').on('change, keyup', function () {
        if ($(this).length > 0) {
            $(this).parent().find('.errors').remove();
            $(this).parent().find('.form-error').removeClass('form-error');
        }
    });

    form.on('submit', function () {
        var current = $(this);

        current.find('.errors').remove();
        current.find('.form-error').removeClass('form-error');

        placeLoaderOnElement(current);
        $.ajax({
            type: 'POST',
            url: current.attr('action'),
            data: current.serialize(),
            dataType: 'json',
            success: function (result) {
                removeLoaderOnElement(current);

                if (result.success === true) {
                    window.location = result.redirect;
                } else {
                    $.each(result.errors, function (key, value) {
                        var el = $('#' + key);
                        var ul = $('<ul/>').addClass('errors');

                        $.each(value, function (key, value) {
                            var li = $('<li/>').addClass('formError');
                            li.text(value);
                            li.appendTo(ul);
                        });

                        ul.insertAfter(el);
                        el.addClass('form-error');
                    });
                }
            },
            error: function () {
                removeLoaderOnElement(current);
                $('.form-error-message').modal('show');
            }
        });

        return false;
    });
});